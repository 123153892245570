<template>
  <div class="container">
        <!-- <div class="header">
      <span>
        <h3>Atualizar stakeholder #{{stakeholder.id}}</h3>
        <p style="text-align:left">Preencha com atenção</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.push('/lista/stakeholder')">voltar</button>
    </div> -->
    <form class="formulario" autocomplete='off'>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
              <v-text-field
                
                v-model="nome"
                name="nome"
                label="Nome"
                outlined
                :error-messages="erros.nome"
                @mousedown="(e) => selecionarcampo(e)"
              ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
              <v-text-field
                
                v-model="telefone"
                name="telefone"
                label="Telefone"
                outlined
                :error-messages="erros.telefone"
                @mousedown="(e) => selecionarcampo(e)"
              ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="12"
        >
              <v-text-field
                
                v-model="email"
                name="email"
                label="Email"
                outlined
                :error-messages="erros.email"
                @mousedown="(e) => selecionarcampo(e)"
              ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="12"
        >
            <v-select
                @mousedown="(e) => selecionarcampo(e, 'stakeholdertipo')"
                :items="stakeholdertipos"
                label="Tipo Stakeholder"
                name="stakeholdertipo"
                v-model="stakeholdertipo"
                :error-messages="erros.stakeholdertipo"
                outlined
        ></v-select>
        </v-col>
      </v-row>  
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          
              <v-select
                @mousedown="(e) => selecionarcampo(e, 'ambiente')"
                :items="ambientes"
                label="Ambiente"
                name="ambiente"
                v-model="ambiente"
                :error-messages="erros.ambiente"
                outlined
        ></v-select>
        </v-col>
      </v-row>    

      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          
              <v-select
                @mousedown="(e) => selecionarcampo(e, 'ambiente')"
                :items="clientes"
                label="Clientes"
                name="clientes"
                v-model="cliente"
                outlined
        ></v-select>
        </v-col>
      </v-row> 

      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-switch
            v-model="status"
            name="status"
            inset
            :label="`Usuário ${status ? 'Principal' : 'Normal'}`"
          ></v-switch>
        </v-col>
      </v-row>  
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-btn
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="submeterform">
            atualizar stakeholder
          </v-btn>
        </v-col>
      </v-row>    
    </form>
  </div>
  
</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import StakeHServico from '@/services/stakeholder.service.js'
import ClienteServico from '@/services/cliente.service.js'

export default {
  data(){
    return {
      telefone:null,
      status: null,
      reqloading: false,
      clientes: [],
      clientesdb: [],
      cliente: null,
      stakeholdertipos: [],
      stakeholdertiposdb: [],
      stakeholdertipo: null,
      ambientes: ['Economy', 'Cliente', 'Todos'],
      ambientesdb: [{nome: 'Economy', id: 1}, {nome: 'Cliente', id: 2}, {nome: 'Todos', id: 3}],
      ambiente: null,
      stakeholder: {},
      nome: null,
      email: null,
      erros: {
        stakeholdertipo: '',
        ambiente: '',
        nome: '',
        email: '',
      },
      id: null,
    }
  },
  methods: {
    async requisitarClientes(){
      this.clientes = await ClienteServico.buscar().then(res => {
        this.clientesdb = res.data
        // return res.data.map(item => ({nome: item.nome, value: item.id}))
        return res.data.map(item => item.nome)
      })
    },
    async submeterform(e){

      e.preventDefault()
      if(this.validacao()){
        this.reqloading = true
        const dadosusuario = {
          nome: this.nome,
          email: this.email,
          telefone: this.telefone,
          principal: this.status,
          id: this.stakeholder.id,
          idusuario: this.stakeholder.idusuario,
          idambiente: this.ambientesdb.filter(el => el.nome === this.ambiente)[0].id,
          idtipo: this.stakeholdertiposdb.filter(el => el.nome === this.stakeholdertipo)[0].id,
          idcliente:this.clientesdb.filter(el => el.nome === this.cliente)[0].id
        }
        await StakeHServico.criar(dadosusuario).then(res => {
          if(res.status === 200){
           this.reqloading = false

            alertar('success', '=)', 'StakeHolder atualizado com sucesso')
          }
        }).catch(e => {
        this.reqloading = false

          console.log('error criarcao stakeholder', e)
          alertar('warning', '=/', 'Falha na atualização do StakeHolder')
        })
        this.reqloading = false
      }
    },
    validacao(){
      let objretorno
      let validado = new Array()
      if(!this.nome || this.nome.trim() === ''){
        validado.push(false)
        this.erros.nome = 'Esse campo é obrigatório'
      }
      if(!this.email || this.email.trim() === ''){
        validado.push(false)
        this.erros.email = 'Esse campo é obrigatório'
      }
      if(!this.telefone || this.telefone.trim() === ''){
        validado.push(false)
        this.erros.telefone = 'Esse campo é obrigatório'
      }
      if(!this.stakeholdertipo || this.stakeholdertipo.trim() === ''){
        validado.push(false)
        this.erros.stakeholdertipo = 'Esse campo é obrigatório'
      }
      if(!this.ambiente || this.ambiente.trim() === ''){
        validado.push(false)
        this.erros.ambiente = 'Esse campo é obrigatório'
      }
      validado.includes(false) ? objretorno = false : objretorno = true
      return objretorno
    },
    selecionarcampo(e, type){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = null
      }
      if(type){
        this.erros[type] = null
      }
    },
    async requisitarStakeholder(){
      this.stakeholder = await StakeHServico.buscar({id: this.id}).then(res => res.data[0])
    },
    async requisitarTiposStakeholder(){
      this.stakeholdertipos = await StakeHServico.buscarTipo().then(res =>{
        this.stakeholdertiposdb = res.data
        return res.data.map(tipo => tipo.nome)
      })
    }
  },
  async mounted(){
    await this.requisitarTiposStakeholder()
    const { id } = this.$route.params
    this.id = id
    await this.requisitarStakeholder()
    this.status = this.stakeholder.principal
    this.ambiente =  this.ambientesdb.filter(el => el.id == this.stakeholder.idambiente)[0].nome 
    this.stakeholdertipo = this.stakeholdertiposdb.filter(el => el.id == this.stakeholder.idtipo)[0].nome
    this.nome = this.stakeholder.nome
    this.email = this.stakeholder.email
    this.telefone = this.stakeholder.fone
    
    await this.requisitarClientes()
    this.cliente = this.stakeholder.nomecliente

  }
}
</script>

<style lang="scss" scoped>
.container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 64px);
  @media screen and (max-width:768px) {
    height: calc(100% - 56px);
  }
}
.header{
  display: flex;
  padding: 1em;
    margin: 0 auto;
  width: 60vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}
.formulario{
  margin: 0 auto;
  width: 900px;
  max-width: 80vw;
  background-color: #f9f9f2;
  box-shadow:  4px 4px 10px #d9d9d9;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
</style>
