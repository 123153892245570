<template>
    <AtualizarStakeholder />
</template>

<script>
import AtualizarStakeholder from '@/components/Stakeholder/AtualizarStakeholder'
export default {
  components: {AtualizarStakeholder},
  data(){
    return{}
  }
}
</script>

<style>

</style>